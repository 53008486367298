import React, { useState } from "react";
import styled from "styled-components";
import Avatar from "@material-ui/core/Avatar";
import image from "../../assets/profile.PNG";
import { FcAutomatic } from "react-icons/fc";

function titleChange() {
  console.log("title change");
}

function UserInfo(props) {
  // const [userTitle, setUserTitle] = useState("내 버킷리스트");
  const userTitle = "Bucket List";
  // const [userName, setUserName] = useState("H");
  const userName = "H";
  // const userTitle = React.useRef(null);

  return (
    <div>
      <TopContainer>
        <Avatar src={image}>{userName}</Avatar>
        <div>version 1.1</div>
      </TopContainer>
      <TitleContainer>
        <span type="text">{userTitle}</span>
        <button onClick={titleChange}>
          <FcAutomatic size="30" />
        </button>
        {/* <input type="text" /> */}
      </TitleContainer>
    </div>
  );
}

export default UserInfo;
const TopContainer = styled.h5`
  display: flex;
  color: #ddd;
  text-align: right;
  // border: 1px solid #000;
  margin: 0;
`;

const TitleContainer = styled.h1`
  /* text-size: 10; */
  color: #673ab7;
  text-align: center;
  & button {
    width: 25%;
    color: #fff;
    border: 1px solid #fff;
    background-color: #fff;
`;

const Reload = styled.h5`
  color: #ddd;
  text-align: right;
  margin: 0;
`;

const avatarConfig = styled.h6`
  color: #ddd;
  width: spacing(15);
  height: spacing(15);
`;

const Input = styled.div`
  max-width: 350px;
  min-height: 10vh;
  background-color: #fff;
  padding: 16px;
  margin: 20px auto;
  border-radius: 5px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    padding: 5px;
  }

  & input {
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid #888;
    width: 90%;
    &:focus {
      border: 1px solid #a673ff;
    }
  }

  & button {
    width: 25%;
    color: #fff;
    border: 1px solid #fff;
    background-color: #fff;
  }
`;
